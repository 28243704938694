.swiper-pagination-bullet {
  background-color: #676767;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}
