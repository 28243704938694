@font-face {
  font-family: Montserrat;
  src: url(./fonts/Montserrat.woff2);
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(./fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url(./fonts/Montserrat-ExtraBold.ttf);
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url(./fonts/Montserrat-Light.otf);
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url(./fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url(./fonts/Montserrat-Regular.ttf);
}

body {
  margin: 0;
  font-family: 'Montserrat-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
